import React, { useState, useEffect } from "react";
import "../Support.scss";
import BreadCrumb from "../../../components/BreadCrumb";
import SupportSidebar from "../../../components/SupportSidebar";
import { ReactComponent as Whatsapp } from "../../../assets/wp.svg";
import { ReactComponent as Phone } from "../../../assets/phone.svg";
import { ReactComponent as Mail } from "../../../assets/email.svg";
import InputMask from "react-input-mask";
import Meta from "../../../components/Meta/Meta";
import ApiService from "../../../api/axios";
import { _t } from "../../../helpers/helpers";
// import Swal from "sweetalert2";
// import { useForm } from "react-hook-form";

const Contact = ({ settings, sidebar }) => {
  const [contact, setContent] = useState("");
  const [pageMeta, setPageMeta] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");
  // const { contactForm, handleSubmit } = useForm();
  // const onSubmit = (data) => console.log(data);

  useEffect(() => {
    ApiService.get("static/details?id=1589").then((resp) => {
      setContent(resp.data.results.content);
      setPageMeta(resp.data.results.meta);
      setBreadcrumbs(resp.data.results.meta.breadcrumbs);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(data);
    ApiService.post("/form/contact", {
      email: email,
      phone: phone,
      full_name: name,
      message: text,
    }).then((res) => {
      // Swal.fire(res.data.title, res.data.message, "success");
      e.target.reset();
    });
  };
  return (
    <div className="support contact">
      <Meta meta={pageMeta} />
      <div className="container">
        <BreadCrumb breadcrumbs={breadcrumbs} />
        <h2 className="title">
          {_t("title.support")}
          <p className="desk-none">{_t("account_content")}</p>
        </h2>
        <div className="row">
          <div className="xl-3 lg-3 md-3 sm-12 positionRelative">
            <SupportSidebar sidebar={sidebar} />
          </div>
          <div className="xl-9 lg-9 md-9 sm-12">
            <div className="supportMain">
              <div className="supportHeader">
                <h4>{contact.description}</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: contact.content,
                  }}
                ></div>
                <span class="voenClass">
                  ИНН 890511060986 | ИП ТАГИЕВ РАИД АТАМОГЛАН ОГЛЫ
                </span>
              </div>
              <div className="supportBody">
                <div className="support-contact-icons">
                  {/* <a
                    rel="noopener noreferrer"
                    href={`${settings.wp_link}`}
                    target="_blank"
                  >
                    <Whatsapp />
                    <span>{settings.numbers}</span>
                  </a> */}
                  {settings.numbers.map((item) => {
                    return (
                      <a href={`tel:${settings.number}`}>
                        <Phone />
                        <span>{item} </span>
                      </a>
                    );
                  })}

                  <a href={`mailto:${settings.email}`}>
                    <Mail />
                    <span>{settings.email}</span>
                  </a>
                </div>

                <form className="contactForm" onSubmit={handleSubmit}>
                  <h4>{_t("title_question_for_us")}</h4>

                  <div className="contactFormInputs">
                    <div className="contactFormInputsChild">
                      <div className="input-group">
                        <input
                          type="text"
                          // {...contactForm("full_name", { required: true })}
                          name="full_name"
                          placeholder={_t("forms.name_placeholer")}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="input-group">
                        <InputMask
                          mask="+\9\9\4 99 999 99 99"
                          // {...contactForm("phone", { required: true })}
                          maskChar=" "
                          name="phone"
                          placeholder={_t("forms.numbers")}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      <div className="input-group">
                        <input
                          type="email"
                          // {...contactForm("email", { required: true })}
                          name="email"
                          placeholder={_t("forms.emails")}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="input-group textarea">
                      <textarea
                        // {...contactForm("text", { required: true })}
                        name="text"
                        id=""
                        cols="30"
                        rows="10"
                        placeholder={_t("forms_your_question")}
                        onChange={(e) => setText(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <button
                    // disabled={!formData.isValid}
                    // onClick={handleContactButton}
                    className="blackButton"
                    type="submit"
                  >
                    {_t("buttons.send")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
