import React, { useContext, useEffect, useState } from "react";
import "./ProductDetail.scss";

import BreadCrumb from "../../components/BreadCrumb";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { FreeMode, Navigation, Thumbs } from "swiper";
import { ReactComponent as Manat } from "../../assets/rubl.svg";
import { ReactComponent as Bag } from "../../assets/Bag.svg";
import { ReactComponent as Heart } from "../../assets/heart.svg";
import { ReactComponent as Share } from "../../assets/share.svg";
import { ReactComponent as Twitter } from "../../assets/twitter.svg";
import { ReactComponent as Tg } from "../../assets/telegram.svg";
import { ReactComponent as Vk } from "../../assets/vk.svg";
import { ReactComponent as Wp } from "../../assets/wp.svg";

import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Rating } from "react-simple-star-rating";
import "react-tabs/style/react-tabs.css";
import ApiService from "../../api/axios";
import { Link } from "react-router-dom";
import Meta from "../../components/Meta/Meta";
import { addTosWishlist } from "../../helpers/functions";
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import { _t } from "../../helpers/helpers";
import AddToCartPopup from "../../components/ProductCard/PopupAddToBasket";
import ShareLink from "react-twitter-share-link";
import { QuantityPicker } from "react-qty-picker";
// import Swal from "sweetalert2";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  cartItemsContext,
  quantityContext,
  tokenContext,
} from "../../components/Store";

const ProductDetail = (props) => {
  const [productDetail, setProductDetail] = useState({
    title_image: "",
    volumes: [
      {
        title_image: "",
      },
    ],
    gender: {
      alias: "",
      title: "",
    },
    author: {
      alias: "",
      full_name: "",
    },
    season: {
      alias: "",
      title: "",
    },
    brand: {
      alias: "",
      title: "",
    },
    groups: [
      {
        alias: "",
        title: "",
      },
    ],
    topNotes: [
      {
        alias: "",
        title: "",
      },
    ],
    heartNotes: [
      {
        alias: "",
        title: "",
      },
    ],
    part_of_day: {
      alias: "",
      title: "",
    },
    baseNotes: [
      {
        alias: "",
        title: "",
      },
    ],
    similars: [],
  });
  const [isActive, setIsActive] = useState(false);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [token, setToken] = useContext(tokenContext);

  const [count, setCount] = useState(1);
  const [volume_id, setVolume] = useState(0);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [pageMeta, setPageMeta] = useState([]);
  const [cartItems, setCartItems] = useContext(cartItemsContext);
  const [quantity, setQuantity] = useContext(quantityContext);
  // const [outOfStock, setOutOfStock] = useState();
  useEffect(() => {
    ApiService.get(`products/details?url=${props.match.params.alias}`).then(
      (resp) => {
        setBreadcrumbs(resp.data.results.meta.breadcrumbs);
        setPageMeta(resp.data.results.meta);
        setVolume(resp.data.results.content.volumes[0].id);

        setProductDetail(resp.data.results.content);
        console.log(productDetail.similars);
        // if (resp.data.results.data.out_of_stock == 1) {
        //   Swal.fire({
        //     position: "center",
        //     icon: "warning",
        //     title: _t("out of stock"),
        //     showConfirmButton: true,
        //     timer: 5000,
        //   });
        // }
      }
    );
  }, [props.match.params.alias]);

  const addToCart = (e, product_id) => {
    if (token) {
      ApiService.post("cart/user/create", {
        product_id,
        volume_id,
        count,
      }).then((resp) => {
        let item = { product_id, volume_id, count };

        let cart = cartItems;

        localStorage.setItem("cartItems", JSON.stringify(cart));
        setCartItems(cart);
        setQuantity(quantity + item.count);
        localStorage.setItem("quantity", quantity + item.count);
        // Swal.fire({
        //   position: "top-end",
        //   icon: "success",
        //   title: _t("message.basket_added"),
        //   showConfirmButton: false,
        //   timer: 1000,
        // });
      });
    } else {
      ApiService.post("cart/guest/create", {
        product_id,
        volume_id,
        count,
        guest_id: localStorage.guest_uid,
      }).then((resp) => {
        let item = { product_id, volume_id, count };

        let cart = cartItems;

        localStorage.setItem("cartItems", JSON.stringify(cart));
        setCartItems(cart);
        setQuantity(quantity + item.count);
        localStorage.setItem("quantity", quantity + item.count);
        // Swal.fire({
        //   position: "top-end",
        //   icon: "success",
        //   title: _t("message.basket_added"),
        //   showConfirmButton: false,
        //   timer: 1000,
        // });
      });
    }
  };

  return (
    <div className="productDetail">
      {isActive ? <AddToCartPopup product={props.product} /> : null}
      <Meta meta={pageMeta} />
      <div className="container">
        <BreadCrumb breadcrumbs={breadcrumbs} />
        <div className="row">
          <div className="xl-1 lg-1 md-1 sm-12"></div>
          <div className="xl-11 lg-11 md-12 sm-12">
            <div className="productDetailHeader">
              <div className="title">{productDetail.title}</div>
              <div className="productCardRating">
                <Rating
                  fillColor={"#A80E13"}
                  readonly={true}
                  allowHalfIcon={true}
                  iconsCount={5}
                  size={20}
                  ratingValue={productDetail.rating * 20} /* Available Props */
                />
                <span>({productDetail.ratingCount})</span>
                <Link to={`/products?brand=${productDetail.brand.alias}`}>
                  <p>{productDetail.brand.title}</p>
                </Link>
              </div>
            </div>
          </div>
          <div className="xl-1 lg-0 md-0 sm-0"></div>
          <div className="xl-5 lg-6 md-6 sm-12 productDetailSlider ">
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              modules={[FreeMode, Navigation, Thumbs]}
              thumbs={{ swiper: thumbsSwiper }}
              className="firstSwiper"
            >
              <SwiperSlide key={0}>
                <LazyLoadImage
                  src={productDetail.title_image}
                  alt="product img a"
                  effect="blur"
                />
              </SwiperSlide>
              {productDetail.media &&
                productDetail.media.map((image) => {
                  return (
                    <SwiperSlide key={image.id}>
                      <LazyLoadImage
                        src={image.main}
                        alt="product img"
                        effect="blur"
                      />
                      {console.log(image.main)}
                    </SwiperSlide>
                  );
                })}

              {productDetail.volumes.map((volume, index) => {
                return (
                  <SwiperSlide key={`volumes_${volume.id}`}>
                    {volume.title_image.id == 0 ? (
                      <>
                        {productDetail.brand.is_special ? (
                          <LazyLoadImage
                            src={productDetail.thumbnail}
                            alt="product img"
                            className="imgPath"
                            effect="blur"
                          />
                        ) : (
                          <img
                            src={`https://api.sivif.ru/frontend/images/volumes/${volume.alias}-site.png`}
                            alt="product img"
                            className="imgPath"
                          />
                        )}
                      </>
                    ) : (
                      <LazyLoadImage
                        src={volume.title_image ? volume.title_image : null}
                        alt="product img"
                        className="imgPath"
                        effect="blur"
                      />
                    )}
                  </SwiperSlide>
                );
              })}
              {productDetail.brand.is_special == 0 ? (
                <div className="hoverEffectSpan">lorem</div>
              ) : null}
            </Swiper>
            <Swiper
              onSwiper={setThumbsSwiper}
              spaceBetween={10}
              slidesPerView={6}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="secondSwiper"
            >
              <SwiperSlide key={0}>
                <LazyLoadImage
                  src={productDetail.title_image}
                  alt="product img a"
                  effect="blur"
                />
              </SwiperSlide>
              {productDetail.media &&
                productDetail.media.map((image) => {
                  return (
                    <SwiperSlide key={image.id}>
                      <LazyLoadImage
                        src={image.main}
                        alt="product img"
                        effect="blur"
                      />
                      {console.log(image.main)}
                    </SwiperSlide>
                  );
                })}
            </Swiper>
            {token ? (
              <div className="heart desk-none">
                <label
                  className="heart"
                  onClick={(e) => addTosWishlist(e, productDetail.id)}
                >
                  <input
                    checked={
                      localStorage.wishlist &&
                      localStorage.wishlist.includes(productDetail.id)
                        ? "checked"
                        : null
                    }
                    className="like-button"
                    type="checkbox"
                  />
                  <Heart />
                </label>
              </div>
            ) : null}
          </div>
          <div className="xl-6 lg-6 md-6 sm-12">
            <div className="productDetailRight">
              <h5>{_t("site_content.select_perfume_volume")}:</h5>
              <div className="productDetailSize">
                <Swiper
                  modules={[Thumbs]}
                  watchSlidesProgress
                  // onSwiper={setThumbsSwiper}
                  spaceBetween={10}
                  noSwiping={true}
                  slidesPerView={2.6}
                >
                  <SwiperSlide key={0}>
                    <div className="swiperThumbsImg">
                      <LazyLoadImage
                        src={productDetail.title_image}
                        alt="product imgf"
                        effect="blur"
                      />
                    </div>
                  </SwiperSlide>
                  {productDetail.volumes.map((volume, index) => {
                    return (
                      <SwiperSlide key={`volumes_${volume.id}`}>
                        {productDetail.brand.is_special ? (
                          <>
                            <label>
                              <input
                                defaultChecked={index == 0}
                                type="radio"
                                name="size"
                              />
                              <div className="sizeCard">
                                {volume.title_image.id == 0 ? (
                                  <LazyLoadImage
                                    src={productDetail.title_image}
                                    alt="product img"
                                    className="imgPath"
                                    effect="blur"
                                  />
                                ) : (
                                  <LazyLoadImage
                                    src={
                                      volume.title_image
                                        ? volume.title_image
                                        : null
                                    }
                                    alt="product img"
                                    className="imgPath"
                                    effect="blur"
                                  />
                                )}
                                <span>{volume.volume}</span>
                                <div className="price">
                                  {volume.price == volume.sale_price ? (
                                    <>
                                      {volume.price}
                                      <Manat />
                                    </>
                                  ) : null}
                                  {volume.price == volume.sale_price ? (
                                    <span>{volume.sale_price}</span>
                                  ) : (
                                    <span>{volume.price}</span>
                                  )}
                                  <Manat />
                                </div>
                              </div>
                            </label>
                          </>
                        ) : (
                          <>
                            <label>
                              <input
                                onChange={(e) => setVolume(volume.id)}
                                defaultChecked={index == 0}
                                type="radio"
                                name="size"
                              />
                              <div className="sizeCard">
                                {volume.title_image.id == 0 ? (
                                  <>
                                    <img
                                      src={`https://api.sivif.ru/frontend/images/volumes/${volume.alias}-site.png`}
                                      alt="product img"
                                      className="imgPath a"
                                    />
                                  </>
                                ) : (
                                  <LazyLoadImage
                                    src={
                                      volume.title_image.thumbnail
                                        ? volume.title_image.thumbnail
                                        : null
                                    }
                                    alt="product img"
                                    className="imgPath"
                                    effect="blur"
                                  />
                                )}
                                <span>{volume.volume}</span>
                                <div className="price">
                                  {volume.sale_price == 0 ||
                                  volume.sale_price == null ? (
                                    volume.price
                                  ) : (
                                    <>
                                      {volume.price != volume.sale_price
                                        ? volume.sale_price
                                        : volume.price}
                                      <Manat />
                                      {volume.price != volume.sale_price ? (
                                        <span>
                                          {volume.price}
                                          <Manat />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </div>
                              </div>
                            </label>
                          </>
                        )}
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>

              <div className="productDetailButtons">
                <QuantityPicker
                  value={count}
                  max={9999}
                  min={1}
                  onChange={(newValue) => {
                    setCount(newValue);
                  }}
                />
                {productDetail.out_of_stock == 0 ? (
                  <button
                    type="button"
                    className="addToCard"
                    onClick={(e) => addToCart(e, productDetail.id)}
                  >
                    <Bag />
                    {_t("buttons.add_to_basket")}
                  </button>
                ) : (
                  <button type="button" className="addToCard" disabled>
                    <Bag />
                    {_t("buttons.add_to_basket")}
                  </button>
                )}
                {token ? (
                  <div className="productDetailHeart resp-none">
                    <label
                      className="heart"
                      onClick={(e) => addTosWishlist(e, productDetail.id)}
                    >
                      <input
                        checked={
                          localStorage.wishlist &&
                          localStorage.wishlist.includes(productDetail.id)
                            ? "checked"
                            : null
                        }
                        className="like-button"
                        type="checkbox"
                      />
                      <Heart />
                    </label>
                  </div>
                ) : null}
              </div>
              <div className="productDetailSocials">
                <div className="newsContentBottom">
                  <CopyToClipboard
                    onCopy={() => {
                      // Swal.fire({
                      //   position: "top-end",
                      //   icon: "success",
                      //   title: _t("message.copied"),
                      //   showConfirmButton: false,
                      //   timer: 1000,
                      // });
                    }}
                    text={window.location.href}
                  >
                    <a href="#">
                      <Share />
                    </a>
                  </CopyToClipboard>
                  <ShareLink link={window.location.href}>
                    {(link) => (
                      <a href={link} target="_blank">
                        <Twitter />
                      </a>
                    )}
                  </ShareLink>
                  <a
                    href={`https://vk.com/share.php?url=${window.location.href}`}
                    target="_blank"
                  >
                    <Vk />
                  </a>
                  <a
                    href={`https://web.whatsapp.com/send?text=${window.location.href}`}
                    target="_blank"
                  >
                    <Wp />
                  </a>
                  <a
                    href={`https://telegram.me/share/url?url=${window.location.href}`}
                    target="_blank"
                  >
                    <Tg />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="xl-1 lg-1 md-1 sm-12"></div>
          <div className="xl-10 lg-10 md-10 sm-12">
            <Tabs>
              <div className="productDetailTab">
                <TabList>
                  <Tab>{_t("site_content.product.parameters")}</Tab>
                  <Tab>{_t("site_content.product.information")}</Tab>

                  {/* <Tab>{_t("site_content.product.comments")}</Tab> */}
                </TabList>
              </div>

              <TabPanel>
                <div className="productDetailIndicators">
                  <div className="productDetailIndicatorsChild">
                    <p>
                      <strong> {_t("forms.date")}:</strong>
                      <span>{productDetail.date}</span>
                    </p>
                    <p>
                      <strong>{_t("forms.brand")}:</strong>
                      <Link to={`/products?brand=${productDetail.brand.alias}`}>
                        {productDetail.brand.title}
                      </Link>
                    </p>
                    <p>
                      <strong> {_t("forms.gender")}:</strong>
                      <Link
                        to={`/products?gender=${productDetail.gender.alias}`}
                      >
                        {productDetail.gender.title}
                      </Link>
                    </p>
                    <p>
                      <strong> {_t("title.parfumer")}:</strong>
                      <Link
                        to={`/products?author=${productDetail.author.alias}`}
                      >
                        {productDetail.author.full_name}
                      </Link>
                    </p>
                    {/* <p>
                      <strong> {_t("forms.season")}:</strong>
                      <span>{_t(productDetail.season.title)}</span>
                    </p> */}
                  </div>
                  <div className="productDetailIndicatorsChild">
                    {productDetail.groups == 0 ? null : (
                      <p>
                        <strong>{_t("forms.groups")}:</strong>
                        {productDetail.groups.map((group) => {
                          return (
                            <Link to={`/products?group=${group.alias}`}>
                              {group.title}
                            </Link>
                          );
                        })}
                      </p>
                    )}

                    {productDetail.topNotes == 0 ? null : (
                      <p>
                        <strong>{_t("forms.top_notes")}:</strong>
                        {productDetail.topNotes.map((topNote) => {
                          return (
                            <Link to={`/products?note=${topNote.alias}`}>
                              {topNote.title}
                            </Link>
                          );
                        })}
                      </p>
                    )}

                    {productDetail.heartNotes == 0 ? null : (
                      <p>
                        <strong>{_t("forms.heart_notes")}:</strong>

                        {productDetail.heartNotes.map((heartNote) => {
                          return (
                            <Link to={`/products?note=${heartNote.alias}`}>
                              {heartNote.title}
                            </Link>
                          );
                        })}
                      </p>
                    )}

                    {productDetail.baseNotes == 0 ? null : (
                      <p>
                        <strong> {_t("forms.base_notes")}:</strong>

                        {productDetail.baseNotes.map((baseNote) => {
                          return (
                            <Link to={`/products?note=${baseNote.alias}`}>
                              {baseNote.title}
                            </Link>
                          );
                        })}
                      </p>
                    )}

                    {/* {productDetail.part_of_day == 0 ? null : (
                      <p>
                        <strong> {_t("forms.part_of_day")}:</strong>
                        <span>{_t(productDetail.part_of_day.title)}</span>
                      </p>
                    )} */}
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div
                  className="productDetailInfo"
                  dangerouslySetInnerHTML={{
                    __html: productDetail.content,
                  }}
                ></div>
              </TabPanel>
            </Tabs>
          </div>
          <div className="xl-1 lg-1 md-1 sm-12"></div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
