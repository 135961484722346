import React, { useContext, useEffect, useState } from "react";
import "./Cart.scss";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
import "../../components/CartCard/CartCard.scss";
import SimpleReactValidator from "simple-react-validator";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as CloseButton } from "../../assets/Cross.svg";

import {
  ReactComponent as ManatMini,
  ReactComponent as Manat,
} from "../../assets/rubl.svg";
import { ReactComponent as CartSvg } from "../../assets/cart.svg";
import InputMask from "react-input-mask";

import {
  cartItemsContext,
  quantityContext,
  tokenContext,
} from "../../components/Store";
import { _t } from "../../helpers/helpers";
import ApiService from "../../api/axios";
import { Rating } from "react-simple-star-rating";
import { QuantityPicker } from "react-qty-picker";
import { ReactComponent as X } from "../../assets/x.svg";
import AddressSection from "../../components/Address";
// import Swal from "sweetalert2";

const Cart = (props) => {
  const [cartPopup, setCartPopup] = useState(false);
  const [cartItems, setCartItems] = useContext(cartItemsContext);
  const [quantity, setQuantity] = useContext(quantityContext);
  const [token, setToken] = useContext(tokenContext);
  const [cartList, setCartList] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalSale, setTotalSale] = useState(0);
  const [count, setCount] = useState(1);
  const [countItem, setCountItem] = useState("");
  const [full_name, setFullname] = useState("");
  const [city, setCity] = useState("Baku");
  const [full_address, setFullAddress] = useState("");
  const [note, setNote] = useState("");
  const [phone, setPhone] = useState("");
  const [payment, setPayment] = useState("cash");
  const [save_data, setSaveData] = useState(false);
  const [postAddress, setPostAddress] = useState(0);
  let validator = new SimpleReactValidator();
  const [alert, setAlert] = useState({
    400: "Hesabınızda kifayyət qədər vəsait yoxdur",
    402: "Kartınızın istifadə müddəti bitmişdir",
    522: "Sessianız bitmişdir",
    500: "bilinməyən xəta",
  });
  const [popupTitle, setPopupTitle] = useState("");
  const [popupMessage, setPopupMessage] = useState("");

  useEffect(() => {
    orderAccepted();
    orderError();
    if (token) {
      ApiService.get("cart/user/list").then((res) => {
        setCartList(res.data.results.data);
        setTotalPrice(res.data.results.total);
        setTotalSale(res.data.results.total_sale);
        setCountItem(res.data.results.quantity);
      });
    } else {
      ApiService.get("cart/guest/list", {
        params: { guest_id: localStorage.guest_uid },
      }).then((res) => {
        setCartList(res.data.results.data);
        setTotalPrice(res.data.results.total);
        setTotalSale(res.data.results.total_sale);
      });
    }
  }, [quantity]);

  const orderAccepted = () => {
    const success = new URLSearchParams(props.location.search).get("success");
    const order_id = new URLSearchParams(props.location.search).get("order_id");
    const status = new URLSearchParams(props.location.search).get("status");
    console.log(success, status);
    if (status == "paid" && order_id) {
      ApiService.post("order/user/checkout", {
        order_id: "#" + order_id,
      }).then((resp) => {
        // Swal.fire({
        //   position: "top-end",
        //   icon: "success",
        //   title: resp.data.title,
        //   text: resp.data.message,
        //   showConfirmButton: false,
        //   timer: 1000,
        // });

        setPopupTitle(resp.data.title);
        setPopupMessage(resp.data.message);
        resetBasket();
        setCartPopup(!cartPopup);
      });
    }
  };
  const orderError = () => {
    const code = new URLSearchParams(props.location.search).get("code");
    const status = new URLSearchParams(props.location.search).get("status");
    console.log(code, status);
    if (status == "error" && code) {
      // Swal.fire({
      //   icon: "error",
      //   title: `${_t("error.title")}`,
      //   text: alert[code],
      //   showConfirmButton: false,
      //   timer: 2000,
      // });
    }
  };

  const basketByQuantity = (quantity, cartItem) => {
    let updatedCartItems = cartItems;
    let updatedProduct = {
      product_id: cartItem.id,
      volume_id: cartItem.volume.id,
      count: quantity,
    };
    console.log(cartItem.id);

    const index = updatedCartItems.findIndex(
      (item) => item.product_id === cartItem.id
    );
    updatedCartItems[index] = updatedProduct;
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    const newQuantity = JSON.parse(localStorage.getItem("cartItems")).reduce(
      (acc, item) => acc + item.count,
      0
    );
    localStorage.setItem("quantity", String(newQuantity));
    setCartItems(updatedCartItems);
    setQuantity(newQuantity);
  };

  const resetBasket = () => {
    setCartItems([]);
    setQuantity(0);
    localStorage.setItem("quantity", 0);
    localStorage.setItem("cartItems", []);
  };
  const getCartProduct = () => {
    if (validator.allValid()) {
      if (token) {
        ApiService.post("order/user/checkout", {
          address_id: postAddress,
          note,
          payment_type: payment,
          items: cartList,
        }).then((res) => {
          if (payment == "online") {
            window.location.href = res.data.url;
          } else {
            setPopupTitle(res.data.title);
            setPopupMessage(res.data.message);
            resetBasket();
            setCartPopup(!cartPopup);
            setCartItems([]);
            setQuantity(0);
            localStorage.setItem("quantity", 0);
            localStorage.setItem("cartItems", []);
          }
        });
      } else {
        let order_fields = {
          address_info: {
            full_address,
            city,
          },
          user_info: {
            full_name,
            phone,
          },
          note,
          payment_type: payment,
          save_data,
          guest_id: localStorage.guest_uid,
        };
        ApiService.post("guest/checkout", order_fields).then((res) => {
          setPopupTitle(res.data.title);
          setPopupMessage(res.data.message);
          resetBasket();
          setCartPopup(!cartPopup);
          setCartItems([]);
          setQuantity(0);
          localStorage.setItem("quantity", 0);
          localStorage.setItem("cartItems", []);
        });
      }
    } else {
      validator.showMessages();
    }
  };
  const closeButton = () => {
    setCartPopup(false);
    document.body.classList.remove("body-overflow");
  };
  const selectedAddress = (data) => {
    setPostAddress(data);
  };

  const deleteCart = (id, count) => {
    // const cartItem = cartItems.find(
    //   (item) => item.product_id === parseInt(productId)
    // );
    // const newCartItems = cartItems.filter(
    //   (item) => item.product_id !== parseInt(productId)
    // );
    // const newQuantity = quantity - count;
    // localStorage.setItem("cartItems", JSON.stringify(newCartItems));
    // localStorage.setItem("quantity", String(newQuantity));
    // setCartItems(newCartItems);
    // setQuantity(newQuantity);

    // console.log(index, cartItems, index);
    // document.getElementById(
    //   `basket_product_${cartItems.find((item) => item.product_id === parseInt(index)).product_id}`
    // ).style.display = "none";
    // let cartItemsDeleted = cartItems;
    // delete cartItemsDeleted[cartItems.find((item) => item.product_id === parseInt(index)).product_id];
    // // const cartDelete = cartList.filter((c) => c.item.id !== index.id);
    // setCartItems(cartItemsDeleted);
    // localStorage.setItem("cartItems", JSON.stringify(cartItemsDeleted));
    // let quantityDecrease = quantity - count;
    // setQuantity(quantityDecrease);
    // localStorage.setItem("quantity", quantityDecrease);
    // init(newCartItems);

    console.log(id);
    if (token) {
      ApiService.delete(`cart/user/delete?cart_id=${id}`).then((resp) => {
        const cartItem = cartItems.find(
          (item) => item.product_id === parseInt(id)
        );
        const newCartItems = cartItems.filter(
          (item) => item.product_id !== parseInt(id)
        );
        const newQuantity = quantity - count;
        localStorage.setItem("cartItems", JSON.stringify(newCartItems));
        localStorage.setItem("quantity", String(newQuantity));
        setCartItems(newCartItems);
        setQuantity(newQuantity);
      });
    } else {
      ApiService.delete(`cart/guest/delete?cart_id=${id}`, {
        params: { guest_id: localStorage.guest_uid },
      }).then((resp) => {
        const cartItem = cartItems.find(
          (item) => item.product_id === parseInt(id)
        );
        const newCartItems = cartItems.filter(
          (item) => item.product_id !== parseInt(id)
        );
        const newQuantity = quantity - count;
        localStorage.setItem("cartItems", JSON.stringify(newCartItems));
        localStorage.setItem("quantity", String(newQuantity));
        setCartItems(newCartItems);
        setQuantity(newQuantity);
      });
    }
  };

  return (
    <div className="cart">
      {cartPopup ? (
        <div className="popup cartPopup">
          <div className="popupContainer">
            <div className="popupInner">
              <Link to="/" className="img">
                <Logo />
              </Link>
              <div className="popupInnerRight">
                <h5>{popupTitle}</h5>
                <p>{popupMessage}</p>
              </div>
              <button className="popupClose" onClick={() => closeButton()}>
                <CloseButton />
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <div className="container">
        {cartList.length > 0 ? (
          <div className="cart-container">
            <div className="cart-header">
              <h5 className="title">{_t("buttons.basket")}</h5>
              <span>
                ({countItem} {_t("sidebar.products")})
              </span>
            </div>
            <div className="row cartTableContent">
              <div className="xl-1 lg-1 md-1 sm-12"></div>
              <div className="xl-5 lg-5 md-5 sm-12 resp-width">
                {token ? (
                  <div className="newAccount">
                    <div className="newAccountContent ">
                      <div className="newAccountTitle deliveryCart">
                        <div>
                          <span>1</span>
                          {_t("title.delivery")}
                        </div>
                      </div>
                      <AddressSection selected={selectedAddress} />
                      <div className="input-group">
                        <textarea
                          name="note"
                          id="note"
                          onChange={(e) => setNote(e.target.value)}
                          cols="30"
                          rows="10"
                          placeholder={`${_t("title.note")}`}
                        ></textarea>
                      </div>
                    </div>
                    <div className="newAccountContent">
                      <div className="newAccountTitle newaccountTitleRandom">
                        <div className="newAccountTitleChild">
                          <span>3</span>
                          {_t("table.header.payment_type")}
                        </div>
                        <p>
                          {_t("title.delivery")}:
                          {totalPrice > 29
                            ? _t("title.free")
                            : _t("min_delivery")}
                        </p>
                      </div>
                      <div className="paymentLabel">
                        <label htmlFor="cash" className="filterRadio">
                          <span>{_t("title.cash.payment")}</span>
                          <input
                            type="radio"
                            onChange={() => setPayment("cash")}
                            name="payment"
                            id="cash"
                          />
                          <span className="checkmark"></span>
                        </label>
                        <label htmlFor="online" className="filterRadio">
                          <span>{_t("title.online.payment")}</span>
                          <input
                            type="radio"
                            onChange={() => setPayment("online")}
                            name="payment"
                            id="online"
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <button
                        className="greenButton desk-none"
                        onClick={getCartProduct}
                      >
                        {_t("title.complate.order")}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="newAccount">
                    <div className="newAccountContent">
                      <div className="newAccountTitle">
                        <span>1</span>
                        {_t("title_personal_info")}
                      </div>
                      <div className="input-group">
                        <input
                          type="text"
                          name="full_name"
                          onChange={(e) => setFullname(e.target.value)}
                          placeholder={_t("forms.name_placeholer")}
                        />
                        {validator.message("full_name", full_name, "required")}
                      </div>

                      <div className="input-group">
                        <InputMask
                          mask="+79999999999"
                          maskChar=" "
                          name="phone"
                          placeholder={`${_t("forms.numbers")}`}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                        {validator.message("phone", phone, "required")}
                      </div>
                    </div>
                    <div className="newAccountContent">
                      <div className="newAccountTitle">
                        <span>2</span>
                        {_t("title.delivery")}
                      </div>
                      <div className="input-group">
                        <input
                          type="text"
                          name="address[city]"
                          placeholder={`${_t("forms.city")}`}
                          onChange={(e) => setCity(e.target.value)}
                        />
                        {validator.message("address[city]", city, "required")}
                      </div>
                      <div className="input-group">
                        <input
                          type="text"
                          name="address[full_address]"
                          onChange={(e) => setFullAddress(e.target.value)}
                          placeholder={`${_t("forms.address")}`}
                        />
                        {validator.message(
                          "address[full_address]",
                          full_address,
                          "required"
                        )}
                      </div>
                      <div className="input-group">
                        <textarea
                          name="note"
                          id="note"
                          onChange={(e) => setNote(e.target.value)}
                          cols="30"
                          rows="10"
                          placeholder={`${_t("title.note")}`}
                        ></textarea>
                      </div>
                    </div>
                    <div className="newAccountContent">
                      <div className="newAccountTitle newaccountTitleRandom">
                        <div className="newAccountTitleChild">
                          <span>3</span>
                          {_t("table.header.payment_type")}
                        </div>
                        <p>
                          ({_t("title.delivery")}: {_t("title.free")})
                        </p>
                      </div>

                      <div className="paymentLabel">
                        <label htmlFor="cash" className="filterRadio">
                          <span>{_t("title.cash.payment")}</span>
                          <input
                            type="radio"
                            value="cash"
                            onSelect={(e) => setPayment("cash")}
                            name="payment"
                            id="cash"
                          />
                          <span className="checkmark"></span>
                        </label>
                        <label htmlFor="online" className="filterRadio">
                          <span>{_t("title.online.payment")}</span>
                          <input
                            type="radio"
                            value="online"
                            onChange={(e) => setPayment("online")}
                            name="payment"
                            id="online"
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>

                      <div className="save-Info">
                        <label htmlFor="" className="filterCheckbox">
                          <span>{_t("title.save.personal.info")}</span>
                          <input
                            type="checkbox"
                            onChange={(e) => setSaveData(!save_data)}
                            name="save_data"
                            id=""
                          />
                          <span className="checkmark"></span>
                        </label>
                        <div className="saveInfoChild">
                          <p>
                            "Məlumatları yadda saxla" düyməsini basaraq xidmət
                            şərtlərini qəbul edirsiniz.
                          </p>
                          <Link to="/support/terms">
                            İstifadəçi razılaşması
                          </Link>
                        </div>
                      </div>
                      <Link to="/login" className="toLogin">
                        {_t("buttons.login")}
                      </Link>
                      <button
                        className="greenButton desk-none"
                        onClick={getCartProduct}
                      >
                        {_t("title.complate.order")}
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="xl-5 lg-5 md-5 sm-12">
                <div className="cartRight">
                  <div className="cartRightParent">
                    <div className="cartRightChild">
                      {cartList.map((cartItem, key) => {
                        return (
                          <div
                            className="CartCard"
                            id={`basket_product_${key}`}
                            key={`${key}-${cartItem}`}
                          >
                            <div className="imgContainer">
                              <img src={cartItem.title_image} alt="order img" />
                            </div>
                            {cartItem.sale_percent ? (
                              <span className="percent">
                                -{cartItem.sale_percent}%
                              </span>
                            ) : null}

                            <div className="orderHistoryDetail">
                              <h6>{cartItem.title}</h6>
                              <div className="ratingCardSize">
                                <div className="rating-card">
                                  <Rating
                                    fillColor={"#A80E13"}
                                    readonly={true}
                                    allowHalfIcon={true}
                                    iconsCount={5}
                                    size={20}
                                    ratingValue={
                                      cartItem.rating * 20
                                    } /* Available Props */
                                  />
                                  {/* <span className="desk-none">
                                    {cartItem.volume}
                                  </span> */}
                                </div>
                              </div>

                              <div className="orderHistoryDetailButton">
                                <div className="left">
                                  <div className="quantity">
                                    <div className="cart-inputs">
                                      <QuantityPicker
                                        value={count}
                                        max={9999}
                                        min={1}
                                        onChange={(newValue) => {
                                          setCount(newValue);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  {/* <span className="resp-none">
                                    {cartItem.volume}
                                  </span> */}
                                </div>
                                {cartItem.sale_percent ? (
                                  <div className="right">
                                    <p>
                                      {cartItem.price -
                                        (cartItem.price *
                                          cartItem.sale_percent) /
                                          100}
                                      <Manat />
                                    </p>
                                    <span>
                                      {cartItem.price}
                                      <ManatMini />
                                    </span>
                                  </div>
                                ) : (
                                  <div className="right">
                                    <p>
                                      {cartItem.price}
                                      <Manat />
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <button
                              type="button"
                              className="deleteCart"
                              onClick={(e) =>
                                deleteCart(cartItem.id, cartItem.count)
                              }
                            >
                              <X />
                              {_t("buttons.remove")}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="cartTotal">
                    <div className="CarttotalPrice">
                      <p>
                        {_t("table.header.total")}
                        <span>
                          {totalPrice} <Manat />
                        </span>
                      </p>
                      <p>
                        {_t("title.delivery")}
                        <span>
                          0 <Manat />
                        </span>
                      </p>
                    </div>
                    <div className="cartTotalChild">
                      <p>
                        {_t("title.total")}

                        <span>
                          {totalPrice}
                          <Manat />
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="orderComplated">
                    <Link to="/">{_t("buttons.back_to_shop")}</Link>
                    <button
                      className="greenButton resp-none"
                      onClick={getCartProduct}
                    >
                      {_t("title.complate.order")}
                    </button>
                  </div>
                </div>
              </div>
              <div className="xl-1 lg-1 md-1 sm-12"></div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="xl-12 lg-12 md-12 sm-12">
              <div className="cartEmpty">
                <div className="cartEptyContent">
                  <Link to="/">
                    <CartSvg />
                  </Link>
                  <p>{_t("cart.empty")}</p>
                  <Link to="/products" className="blackButton cartEmptyButton">
                    {_t("cart.back_to_shop")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
